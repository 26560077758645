<template>
  <onboard-wrapper title="Payment Setup">
    <card-add-display initial-setup @cardAdded="$router.push('/')" />
  </onboard-wrapper>
</template>
<script>
import { mapState } from "vuex";
// import firebase from "firebase/app";
import OnboardWrapper from "../../components/OnboardWrapper.vue";
import "firebase/auth";
import "firebase/firestore";
import CardAddDisplay from "../../components/CardAddDisplay.vue";
export default {
  components: {
    OnboardWrapper,
    CardAddDisplay,
  },
  data() {
    return {
      cardNumber: "",
      password: "",
      loading: false,
      errorMessage: "",
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.currentUser,
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
  },
  methods: {},
};
</script>
